import { DefaultTheme } from 'styled-components'

const theme: DefaultTheme = {
  name: 'light',
  bgColor: '#ffffff',
  bgColorSecondary: '#f4f8fd',
  textColor: '#222b45',
  textColorSecondary: '#3e464f',
  textColorInverted: '#ffffff',
  linkColor: '#045fb4',
  linkColorHover: '#045fb4',
  cardShadow: '0 10px 10px rgba(0, 0, 0, 0.08)',
  cardShadowHover: '0 4px 60px 0 rgba(0, 0, 0, 0.2)',
  btnShadow: 'hsla(0,0%,42.4%,0.2)',
  btnShadowHover: 'hsla(0,0%,42.4%,0.3)',
  borderColor: '#e4e9f2',
  colors: {
    primary: '#3366ff',
  },
}

export default theme
