import React, { useState } from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import * as S from './_styles'

interface NavbarProps {
  changeTheme: () => void
}

export default function Navbar({ changeTheme }: NavbarProps) {
  const [isOpened, setMenuState] = useState<boolean>(false)

  const toggleMenu = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    e.stopPropagation()

    setMenuState(!isOpened)
  }

  return (
    <S.Wrapper className='navbar navbar-expand-lg navbar-light'>
      <div className='container'>
        <Link to='/' className='navbar-brand d-flex'>
          <StaticImage
            src='../../../static/images/logo.svg'
            alt='Logo Coder Arena'
            width={103}
            height={28}
          />
        </Link>

        <button
          type='button'
          className={`navbar-toggler ${isOpened ? '' : 'collapsed'}`}
          data-bs-toggle='collapse'
          data-bs-target='#navbarNav'
          aria-controls='navbarNav'
          aria-expanded={isOpened}
          aria-label='Abrir ou Fechar navegação'
          onClick={toggleMenu}
        >
          <span className='navbar-toggler-icon'></span>
        </button>

        <div
          className={`navbar-collapse collapse ${isOpened ? 'show' : ''}`}
          id='navbarNav'
        >
          <ul className='navbar-nav'>
            <li className='nav-item'>
              <Link
                to='/categories/programacao/'
                activeClassName='active'
                className='nav-link'
                aria-current='page'
              >
                Programação
              </Link>
            </li>
            <li className='nav-item'>
              <Link
                to='/categories/dev-ops/'
                activeClassName='active'
                className='nav-link'
                aria-current='page'
              >
                DevOps
              </Link>
            </li>
            {/* <li className='nav-item'>
              <Link
                to='/categories/front-end/'
                activeClassName='active'
                className='nav-link'
                aria-current='page'
              >
                Front-end
              </Link>
            </li>
            <li className='nav-item'>
              <Link
                to='/categories/carreira/'
                activeClassName='active'
                className='nav-link'
                aria-current='page'
              >
                Carreira
              </Link>
            </li> */}
            <li className='nav-item'>
              <Link
                to='/categories/wiki/'
                activeClassName='active'
                className='nav-link'
                aria-current='page'
              >
                Wiki
              </Link>
            </li>
            <li className='nav-item'>
              <a
                className='nav-link'
                aria-current='page'
                href='https://pages.coderarena.com.br/vip/'
                target='_blank'
                rel='noreferrer noopener'
              >
                Newsletter
              </a>
            </li>
          </ul>
        </div>

        <S.ChangeTheme
          type='button'
          className='btn btn-sm d-none d-lg-flex rounded-circle'
          onClick={changeTheme}
        >
          <svg width='16' height='16' className='bi' focusable='false'>
            <path d='M8 15A7 7 0 1 0 8 1v14zm0 1A8 8 0 1 1 8 0a8 8 0 0 1 0 16z' />
          </svg>
        </S.ChangeTheme>
      </div>
    </S.Wrapper>
  )
}
