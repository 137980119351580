import React from 'react'
import type { GatsbyBrowser } from 'gatsby'
import Layout from './src/components/layout'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'dracula-prism/dist/css/dracula-prism.min.css'
import '@fontsource/open-sans/300.css'
import '@fontsource/open-sans/400.css'
import '@fontsource/open-sans/600.css'
import '@fontsource/open-sans/700.css'
import '@fontsource/source-serif-pro/400.css'
import '@fontsource/source-serif-pro/600.css'
import '@fontsource/source-serif-pro/700.css'
import '@fontsource/fira-code/300.css'
import '@fontsource/fira-code/400.css'
import '@fontsource/fira-code/500.css'
import '@fontsource/fira-code/600.css'
import '@fontsource/fira-code/700.css'

declare global {
  interface Window {
    analytics: any
  }
}

const wrapPageElement: GatsbyBrowser['wrapPageElement'] = ({
  element,
  props,
}) => {
  return <Layout {...props}>{element}</Layout>
}

const onRouteUpdate: GatsbyBrowser['onRouteUpdate'] = () => {
  window.analytics && window.analytics.page()
}

export { wrapPageElement, onRouteUpdate }
