import { DefaultTheme } from 'styled-components'

const theme: DefaultTheme = {
  name: 'dark',
  bgColor: '#111111',
  bgColorSecondary: '#141920',
  textColor: '#c5cee0',
  textColorSecondary: '#cccccc',
  textColorInverted: '#222b45',
  linkColor: '#598bff',
  linkColorHover: '#598bff',
  cardShadow: '0 4px 5px 5px rgba(0, 0, 0, 0.1)',
  cardShadowHover: '0 4px 5px 10px rgba(0, 0, 0, 0.1)',
  btnShadow: 'rgba(10, 10, 10, 0.5)',
  btnShadowHover: 'rgba(10, 10, 10, 0.5)',
  borderColor: '#1d1d1d',
  colors: {
    primary: '#3366ff',
  },
}

export default theme
