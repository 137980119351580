import styled from 'styled-components'

const Wrapper = styled.div`
  color: ${props => props.theme.textColor};
  position: relative;
  display: block;
  margin-top: 3rem;
  padding-top: 3rem;
  padding-bottom: 3rem;
  border-top: 1px solid transparent;
  border-top-color: ${props => props.theme.borderColor};

  svg {
    fill: ${props => props.theme.textColor};
  }
`

export { Wrapper }
