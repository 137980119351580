import styled from 'styled-components'

const Wrapper = styled.nav`
  background-color: ${props => props.theme.bgColor};
  padding: 10px 0;
  border-bottom: 1px solid transparent;
  border-bottom-color: ${props => props.theme.borderColor};

  .navbar-toggler:focus {
    box-shadow: none;
  }

  .navbar-nav .nav-link,
  .navbar-nav .nav-link.active {
    font-size: 1rem;
    font-weight: normal;
    color: ${props => props.theme.textColor};
    letter-spacing: 0.3px;
  }

  .navbar-nav .nav-link:hover,
  .navbar-nav .nav-link:focus {
    color: ${props => props.theme.textColor};
  }

  .navbar-nav .nav-link.active {
    font-weight: 600;
  }

  @media only screen and (min-width: 992px) {
    padding: 20px 0;

    .navbar-brand,
    .navbar-nav .nav-item {
      margin-right: 35px;
    }

    .navbar-brand,
    .navbar-nav .nav-item,
    .navbar-nav .nav-link {
      padding: 0;
    }
  }
`

const ChangeTheme = styled.button`
  padding: 0.25rem;
  fill: ${props => props.theme.textColor};

  &:focus {
    box-shadow: none;
  }
`

export { Wrapper, ChangeTheme }
