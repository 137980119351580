import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import * as S from './_styles'

export default function Footer() {
  const { site } = useStaticQuery<Queries.SocialLinksQuery>(
    graphql`
      query SocialLinks {
        site {
          siteMetadata {
            social {
              instagram {
                link
              }
              youtube {
                link
              }
              twitter {
                link
              }
            }
          }
        }
      }
    `
  )

  const date = new Date()
  const social = site?.siteMetadata.social

  return (
    <S.Wrapper>
      <svg xmlns='http://www.w3.org/2000/svg' style={{ display: 'none' }}>
        <symbol id='instagram' viewBox='0 0 16 16'>
          <path d='M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.917 3.917 0 0 0-1.417.923A3.927 3.927 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.916 3.916 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.926 3.926 0 0 0-.923-1.417A3.911 3.911 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0h.003zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599.28.28.453.546.598.92.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.47 2.47 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.478 2.478 0 0 1-.92-.598 2.48 2.48 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233 0-2.136.008-2.388.046-3.231.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92.28-.28.546-.453.92-.598.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045v.002zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92zm-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217zm0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334z'></path>
        </symbol>
        <symbol id='youtube' viewBox='0 0 16 16'>
          <path d='M8.051 1.999h.089c.822.003 4.987.033 6.11.335a2.01 2.01 0 0 1 1.415 1.42c.101.38.172.883.22 1.402l.01.104.022.26.008.104c.065.914.073 1.77.074 1.957v.075c-.001.194-.01 1.108-.082 2.06l-.008.105-.009.104c-.05.572-.124 1.14-.235 1.558a2.007 2.007 0 0 1-1.415 1.42c-1.16.312-5.569.334-6.18.335h-.142c-.309 0-1.587-.006-2.927-.052l-.17-.006-.087-.004-.171-.007-.171-.007c-1.11-.049-2.167-.128-2.654-.26a2.007 2.007 0 0 1-1.415-1.419c-.111-.417-.185-.986-.235-1.558L.09 9.82l-.008-.104A31.4 31.4 0 0 1 0 7.68v-.123c.002-.215.01-.958.064-1.778l.007-.103.003-.052.008-.104.022-.26.01-.104c.048-.519.119-1.023.22-1.402a2.007 2.007 0 0 1 1.415-1.42c.487-.13 1.544-.21 2.654-.26l.17-.007.172-.006.086-.003.171-.007A99.788 99.788 0 0 1 7.858 2h.193zM6.4 5.209v4.818l4.157-2.408L6.4 5.209z' />
        </symbol>
        <symbol id='rss' viewBox='0 0 16 16'>
          <path d='M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm1.5 2.5c5.523 0 10 4.477 10 10a1 1 0 1 1-2 0 8 8 0 0 0-8-8 1 1 0 0 1 0-2zm0 4a6 6 0 0 1 6 6 1 1 0 1 1-2 0 4 4 0 0 0-4-4 1 1 0 0 1 0-2zm.5 7a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z' />
        </symbol>
      </svg>

      <div className='container'>
        <footer className='d-flex flex-wrap align-items-center'>
          <div className='col-12 col-md-auto d-flex align-items-center justify-content-center justify-content-md-start me-md-auto'>
            <span className='text-reset text-center'>
              © {date.getFullYear()}, Coder Arena. Todos os direitos reservados.
            </span>
          </div>

          <ul className='nav col-12 col-md-4 justify-content-center justify-content-md-end list-unstyled d-flex mt-4 mt-md-0 '>
            {social && (
              <>
                <li className='me-4'>
                  <a
                    href={social.youtube.link}
                    target='_blank'
                    rel='noreferrer noopener'
                    title='Inscreva-se no Canal do YouTube'
                  >
                    <svg className='bi' width='24' height='24'>
                      <use xlinkHref='#youtube'></use>
                    </svg>
                  </a>
                </li>
                <li className='me-4'>
                  <a
                    href={social.instagram.link}
                    target='_blank'
                    rel='noreferrer noopener'
                    title='Siga-me no Instagram'
                  >
                    <svg className='bi' width='24' height='24'>
                      <use xlinkHref='#instagram'></use>
                    </svg>
                  </a>
                </li>
              </>
            )}
            <li>
              <a
                href='/rss.xml'
                target='_blank'
                rel='noreferrer noopener'
                title='Acompanhe o Feed'
              >
                <svg className='bi' width='24' height='24'>
                  <use xlinkHref='#rss'></use>
                </svg>
              </a>
            </li>
          </ul>
        </footer>
      </div>
    </S.Wrapper>
  )
}
