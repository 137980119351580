import React, { ReactNode } from 'react'
import { DefaultTheme, ThemeProvider } from 'styled-components'
import GlobalStyle from '../../styles/global'
import light from '../../styles/themes/light'
import dark from '../../styles/themes/dark'
import usePersistedState from '../../hooks/usePersistedState'
import Navbar from '../navbar'
import Footer from '../footer'

interface LayoutProps {
  children: ReactNode
}

export default function Layout({ children }: LayoutProps) {
  const [theme, setTheme] = usePersistedState<DefaultTheme>('theme', light)
  const changeTheme = () => setTheme(theme.name === 'light' ? dark : light)

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />

      <Navbar changeTheme={changeTheme} />

      {children}

      <Footer />
    </ThemeProvider>
  )
}
