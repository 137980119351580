import { createGlobalStyle } from 'styled-components'

export default createGlobalStyle`
  body {
    font-family: 'Open Sans', sans-serif;
    background-color: ${props => props.theme.bgColor};
    color: ${props => props.theme.textColor};
  }

  body * {
    outline: none;
  }

  a {
    color: ${props => props.theme.linkColor};
    text-decoration: none;
    cursor: pointer;

    &:hover {
      color: ${props => props.theme.linkColorHover};
    }
  }

  *,
  :after,
  :before {
    background-repeat: no-repeat;
    box-sizing: border-box;
  }

  :after,
  :before {
    text-decoration: inherit;
    vertical-align: inherit;
  }

  ::selection {
    color: #000;
    text-shadow: none;
    background-color: #b3d4fc;
  }

  /* Override: Bootstrap */
  @media only screen and (min-width: 1400px) {
    .container {
      max-width: 1140px;
    }
  }

  /* Media Queries */

  // Small devices (landscape phones, 576px and up)
  @media only screen and (min-width: 576px) {}

  // Medium devices (tablets, 768px and up)
  @media only screen and (min-width: 768px) {}

  // Large devices (desktops, 992px and up)
  @media only screen and (min-width: 992px) {}

  // X-Large devices (large desktops, 1200px and up)
  @media only screen and (min-width: 1200px) {}

  // XX-Large devices (larger desktops, 1400px and up)
  @media only screen and (min-width: 1400px) {}

  // Range devices (between)
  @media only screen and (min-width: 768px) and (max-width: 1199.98px) {}
`
